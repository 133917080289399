<template>
  <div class="row">
    <div class="w-100" style="height: 64px">
      <div class="p-4 row">
        <div class="w-100">
          <ol class="step-indicator">
            <li
              class=""
              v-for="(step, index) in stepName"
              :key="index"
              :class="{
                complete: index + 1 === stepName.length,
                stepactive: activeState >= index + 1,
              }"
              style="cursor: pointer;"
              @click="$emit('changeStep',index + 1)"
            >
              <div
                class=" d-flex"
                style="align-items: center"
                :class="{ roundStep: activeState === index + 1 }"
              >
                <div class="step" :class="{ active: activeState >= index + 1 }">
                  <span>{{ index + 1 }}</span>
                </div>
              </div>
              <h5 class="font-medium">{{ step }}</h5>
            </li>
            <!--<li>
              <div class="step">
                <span>2</span>
              </div>
              <h5 class="font-medium">Data Source</h5>
            </li>
            <li class="complete">
              <div class="step">
                <span>3</span>
              </div>
              <h5 class="font-medium">KPI</h5>
            </li>-->
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["stepName", "activeState"],
};
</script>

<style scoped>
.step-indicator {
  border-collapse: separate;
  display: table;
  margin-left: 0px;
  position: relative;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  padding-left: 0;
  z-index: 0;
}

.step-indicator li {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.step-indicator .step {
  background-color: #e8edf5;
  border-radius: 50%;
  color: #8394b4;
  font-size: 24px;
  height: 56px;
  margin: 0 auto;
  position: relative;
  width: 56px;
  z-index: 1;
  border: 2px solid #8394b4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roundStep {
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  font-size: 24px;
  height: 64px;
  line-height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  border: 2px solid #050505;
}

.step-indicator li::after {
  background-color: #8394b4;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 100%;
  top: 28px;
}

.step-indicator li.stepactive::after {
  border: 1.5px solid #050505;
}

.step-indicator li.complete::after {
  left: -50%;
}

.step.active {
  border: 1.5px solid #050505;
  background-color: #050505 !important;
}

.step-indicator span {
  color: #8394b4;
}

.step-indicator h5 {
  color: #8394b4;
  font-family: ProximaNovaRegular;
  margin-top: 7px;
}

.step-indicator .stepactive span {
  color: #fff;
}

.step-indicator .stepactive h5 {
  color: #050505;
  font-family: ProximaNovaBold;
}
</style>
